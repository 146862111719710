.FadeTransition {
  @mixin transition {
    animation-name: FadeTransitionAnimation;
    animation-duration: 150ms;
    animation-timing-function: linear;
    animation-fill-mode: both;
  }

  &-appear,
  &-enter {
    opacity: 0;
  }
  
  &-appear-active,
  &-enter-active {
    opacity: 1;
    @include transition;
  }
  
  &-appear-done,
  &-enter-done {
    opacity: 1;
  }
  
  &-exit {
    opacity: 1;
  }
  
  &-exit-active {
    opacity: 0;
    @include transition;
  }
  
  &-exit-done {
    opacity: 0;
  }
}

@keyframes FadeTransitionAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}