
@import "../styles/index.scss";

.RadialProgressIndicator {
  user-select: none;
  transition: transform 300ms $expoOut;

  circle,
  path,
  text {
    shape-rendering: geometricPrecision;
  }

  .background {
    stroke: $gray10;
    &.filled {
      fill: white;
      @include whenDark {
        stroke: $gray70;
        fill: $key90;
      }
    }
  }

  .progress {
    stroke: $primaryExtraDark;
    stroke-opacity: 1;
    stroke-dasharray: var(--circumference);
    stroke-dashoffset: var(--circumference);
    transform: translateY(-2px) rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 1s $expoOut, stroke 700ms;

    @include whenDark {
      stroke: $key10;
    }
  }

  &.DidRecentlyChange {
    .progress {
      stroke: $primaryKey;
      transition: stroke-dashoffset 1s $expoOut, stroke 50ms;
    }

    text {
      fill: $primaryKey;
      transition: fill 50ms;
    }

    /*animation: RadialProgressIndicator-scaleIn-elastic 530ms linear;
    animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);

    @keyframes RadialProgressIndicator-scaleIn-elastic {
      0.00% {
        transform: scale(1.0);
      }

      20.25% {
        transform: scale(1.2);
      }

      60.12% {
        transform: scale(0.99);
      }

      100.00% {
        transform: scale(1.0);
      }
    }*/
  }
  
  text {
    fill: $primaryExtraDark;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.025em;
    font-feature-settings: 'tnum'on, 'lnum'on;
    text-rendering: optimizeLegibility;

    transition: fill 700ms;

    &.largeNumber {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.0em;
    }

    @include whenDark {
      fill: white;
      font-weight: bold;
    }
  }

}