@import "./colors.scss";
@import "./hairline.scss";
@import "./layout.scss";
@import "./responsive.scss";
@import "./typography.scss";

@mixin whenHoverSupported() {
  @media (hover) {
    @content;
  }
}

@mixin onHover() {
  @include whenHoverSupported {
    &:hover {
      @content;
    }
  }
}
