@import "../styles/index.scss";

.EssayProgressIndicator {
  @mixin whenFlat() {
    @at-root #{selector-append(".flat", &)} {
      @content;
    }
  }  

  width: 332px; // 344px
  height: 400px;

  padding-top: 47px;
  @include whenFlat {
    padding-top: 24px;
    height: 43px;
  }
  
  --outlineColor: white;
  @include whenDark {
    --outlineColor: #{$key90};
  }
  
  $indicatorWidth: 58px;
  $flatTop: 1px;
  $transitionDetailDuration: 500ms;
  $transitionPositionDuration: 1350ms;
  
  transition: padding-top $transitionPositionDuration $expoOut, height $transitionPositionDuration $expoOut;
  @mixin detailTransition {
    transition: all $transitionDetailDuration $expoOut;
  }

  .Inner {
    width: 284.91px;
    height: 318.91px;
    position: relative;
    margin: 0px auto;
    // margin-right: 29px;

    @include whenFlat {
      width: 291px;
    }

    &>svg {
      stroke: $gray10;
      fill: $gray10;

      @include whenDark {
        stroke: $gray60;
        fill: $gray70;
      }
    }
  }

  .Indicators {
    position: absolute;
    top: 0;
    left: -1 * $indicatorWidth / 2;
    right: -1 * $indicatorWidth / 2;
    bottom: 0;

    .Indicator {
      width: $indicatorWidth;
      height: 68px;
      position: absolute;
      transition: top $transitionPositionDuration $expoOut, left 1200ms $expoOut;
      white-space: nowrap;

      svg.RadialProgressIndicator {
        * {
          @include detailTransition;  
        }
      }

      @include whenFlat {
        /* Gross coupling. Sorry. HACK */
        svg.RadialProgressIndicator {
          circle {
            r: calc(21px / 2);
          }
          
          text {
            font-size: 11px;
            font-weight: bold;
            baseline-shift: 1px;
            letter-spacing: 0.008em;

            &.largeNumber {
              font-size: 9.5px;
              baseline-shift: 1px;
            }
          }
        }
      }

      .Label {
        @include labelStyle;
        text-align: center;
        display: block;
        position: absolute;
        left: -16px;
        right: -16px;
        top: 3px;
        text-transform: lowercase;
        color: $gray60;
        z-index: 0;
        
        @include detailTransition;
        @include whenFlat {
          @include labelSmallStyle;
          top: $flatTop;
        }

        &::before {
          content: attr(title);
          position: absolute;
          -webkit-text-stroke: 4px var(--outlineColor);
          color: transparent;
          z-index: -1;
        }

        @include whenDark {
          color: $key00;
        }
      }

      &.started .Label {
        top: -4px;
        @include detailTransition;

        @include whenFlat {
          top: $flatTop;
          font-weight: 600;
        }
      }

      &.unstarted .Label {
        font-weight: normal;
        color: $gray40;
        @include detailTransition;
        
        @include whenFlat {
          opacity: 0.5;
        }

        @include whenDark {
          color: $gray00;
        }
      }

      .Point {
        position: absolute;
        top: 29.5px;
        left: 50%;
        line-height: 0;
        transform: translate(-50%, -50%);
        z-index: 1;

        .Unstarted {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: $gray10;
          @include detailTransition;

          @include whenFlat {
            width: 8px;
            height: 8px;
          }

          @include whenDark {
            background-color: $gray60;
          }
        }
      }

      &.complete {
        .Label {
          color: $key70;
          font-weight: 600;

          @include whenDark {
            color: $key10;
          }
        }

        .Point svg {
          background-color: $primaryDark;
          border-radius: 50%;
          stroke: $key00;

          @include whenDark {
            background-color: $key60;
          }
        }
      }

      &.LongTerm {
        &.unstarted .Point svg {
          fill: $gray20;
          stroke: $gray20;

          @include whenDark {
            fill: $gray30;
            stroke: $gray30;
          }
        }

        width: 62px;
        height: 51px;

        &.unstarted .Label {
          top: 0px;
          font-weight: 600;
          color: $gray40;
          @include whenFlat {
            top: $flatTop;
            opacity: 1;
          }

          @include whenDark {
            color: white;
          }
        }
      }

      .NextLevel {
        @include detailTransition;

        opacity: 1;
        @include whenFlat {
          opacity: 0;
        }

        text-transform: uppercase;
        font-family: $UIFont;
        font-size: 9px;
        letter-spacing: 0.03em;
        color: $gray30;
        position: absolute;
        text-align: center;
        left: -3px;
        right: -5px;
        top: 51px;
        z-index: 0;

        &::before {
          content: "NEXT LEVEL";
          position: absolute;
          color: transparent;
          -webkit-text-stroke: 4px var(--outlineColor);
          z-index: -1;
        }

        @include whenDark {
          color: $gray10;
        }
      }
    }
  }
}